/* Centered search bar with professional styling */
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 500px;
}

.search-input {
  width: 100%;
  padding: 12px 20px 12px 50px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  outline: none;
  border-color: #3892CE;
  box-shadow: 0 4px 12px rgba(56, 146, 206, 0.3);
}

.search-icon {
  position: absolute;
  left: 15px;
  color: #ccc;
  font-size: 1.2rem;
  pointer-events: none;
  transition: color 0.3s ease;
}

.search-input:focus + .search-icon {
  color: #3892CE;
}

.search-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.company-logo {
  width: 60%;
  margin-bottom: 20px;
}

.open-sidebar-button {
  background-color: #3892CE;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.open-sidebar-button:hover {
  background-color: #4F8CB1;
}

.open-sidebar-icon {
  font-size: 1.5rem;
}

.home-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.home-page-content  h3 {
  color: #3892CE;
}