/* ProductUploadForm.css */

.product-upload-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .form-title {
    font-size: 24px;
    color: #3892CE;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .product-upload-form .form-group {
    margin-bottom: 1rem;
    position: relative; /* Ensure dropdown stays within the bounds */
  }
  
  .product-upload-form select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    outline: none;
    appearance: none; /* Remove default arrow for custom styling */
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='gray' class='bi bi-chevron-down'%3E%3Cpath fill-rule='evenodd' d='M3.646 6.646a.5.5 0 0 1 .708 0L8 10.293l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1em;
    transition: border-color 0.3s;
  }
  
  .product-upload-form input[type="text"],
  .product-upload-form input[type="number"],
  .product-upload-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .product-upload-form input[type="text"]:focus,
  .product-upload-form input[type="number"]:focus,
  .product-upload-form textarea:focus,
  .product-upload-form select:focus {
    border-color: #3892CE;
  }
  
  .file-input input[type="file"] {
    padding: 0.5rem;
    background-color: #e3fafd;
    border: 2px dashed #99C3D9;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-button {
    display: block;
    width: 100%;
    padding: 12px;
    background: #4F8CB1;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background: #3892CE;
  }
  
  @media (max-width: 768px) {
    .product-upload-container {
      padding: 1.5rem;
    }
  
    .form-title {
      font-size: 20px;
    }
  
    .upload-button {
      padding: 10px;
    }
  }
  