/* ProductsDisplay.css */

.product-display-container {
    background-color: white;
    z-index: 100000;
    background-size: contain;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This makes sure the container takes the full viewport height */
  }
  
  .logo-spinner {
    animation: spin 2s linear infinite;
    width: 130px; /* Set the logo width */
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Vertical product list */
  .vertical-product-list {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between product cards */
  }
  
  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3892CE; /* Dark background for the headline */
    color: white !important; /* White text color */
    padding: 10px;
    margin-top: 20px;
  }
  
  h1 {
    color: #99C3D9 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px !important;
    font-family: serif;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 22px;
    padding-top: 30px;
    color: #D7C6AE;
    font-family: -webkit-body;
    padding-bottom: 70px;
  }
  
  .row {
    gap: 20px;
  }

  @media screen and (max-width: 768px) {
    .row {
      gap: 0;
    }
}

.col-6 {
  margin-top: 10px;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 600px;
}

.search-input {
  width: 100%;
  padding: 12px 20px 12px 50px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  outline: none;
  border-color: #3892CE;
  box-shadow: 0 4px 12px rgba(56, 146, 206, 0.3);
}

.search-icon {
  position: absolute;
  left: 15px;
  color: #ccc;
  font-size: 1.2rem;
  pointer-events: none;
  transition: color 0.3s ease;
}

.search-input:focus + .search-icon {
  color: #3892CE;
}
