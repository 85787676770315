/* 
.navbar-nav {
  width: 100%;
  text-align: left; 
  padding-left: 10px !important;
}

.nav-link {
  padding: 10px 20px; 
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); 
  font-size: 34px !important;
  color: #D7C6AE !important;
  border-bottom: 1px solid !important;
}
.nav-link:hover {
  background-color: #D7C6AE;
  color: #304121 !important;
}


.navbar-nav {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.navbar-custom {
  background-color: #3892CE; 
  border-bottom: 1px solid #444; 
}

.navbar-brand {
  font-family: 'Your font-family', sans-serif; 
  font-size: 1.5rem; 
  color: #D7C6AE;
  padding-left: 10px;
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0); 
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-nav {
  width: auto; 
  text-align: left; 
  background-color: rgb(79, 140, 177, 0.5);
}

.nav-link {
  color: #D7C6AE;
  padding: 10px 20px; 
  font-size: 1rem; 
  border-bottom: none; 
}

.nav-link:hover {
  background-color: #D7C6AE;
  color: #304121;
}

.navbar-collapse {
  position: relative; 
  top: auto; 
  right: auto; 
  height: auto; 
  width: auto; 
  background-color: transparent; 
  transform: none; 
}

@media (min-width: 992px) {
  .navbar-nav {
    display: flex; 
    flex-direction: row;
    justify-content: flex-end;
  }

  .nav-link {
    font-size: 1.2rem; 
    padding: 0.5rem 1rem; 
  }

  .navbar-collapse {
    transform: none !important;
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.25rem; 
  }

  .navbar-nav {
    padding-left: 0; 
    flex-direction: column; 
    align-items: center; 
  }

  .nav-link {
    font-size: 1.5rem; 
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); 
  }

  .navbar-collapse {
    position: fixed; 
    top: 0;
    left: 0;
    height: 100%; 
    width: 100%;
    background-color: rgb(132, 118, 79, 0.8); 
    z-index: 9999;
    transition: transform 0.3s ease-out;
    transform: translateX(100%);
  }

  .navbar-collapse.collapsing {
    transition: none;
    transform: translateX(100%);
  }

  .navbar-collapse.show {
    transform: translateX(0);
  }
}

.navbar-brand {
  padding: 10px;
  color: white !important;
}

.navbar-dark {
  padding: 10px !important;
}


.navbar-collapse {
position: fixed; 
top: 0;
left: 0;
height: 100%; 
width: 100%; 
background-color: rgba(132, 118, 79, 0.9); 
z-index: 9999; 
transition: transform 0.5s ease, opacity 0.5s ease; 
transform: translateX(-100%); 
opacity: 0; 
}

.navbar-collapse.collapsing {
transition: none; 
opacity: 0.5; 
}

.navbar-collapse.show {
transform: translateX(0);
opacity: 1; 
}

.navbar-toggler.collapsed .navbar-toggler-icon {
transition: transform 0.3s ease;
transform: rotate(0deg); 
}

.navbar-toggler .navbar-toggler-icon {
transition: transform 0.3s ease;
transform: rotate(90deg); 
}




@keyframes slideIn {
0% {
  transform: scale(0.95) rotate(-5deg);
  opacity: 0;
}
100% {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}
}

.navbar-collapse {
display: block; 
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(132, 118, 79, 0.95); 
transform: scale(0.95) rotate(-5deg); 
opacity: 0; 
transition: visibility 0s, opacity 0.5s linear;
visibility: hidden; 
}

.navbar-collapse.show {
animation: slideIn 0.5s forwards; 
visibility: visible; 
}

.nav-link {
opacity: 0;
transform: translateY(20px);
transition: opacity 0.5s ease, transform 0.5s ease;
}

.navbar-collapse.show .nav-link {
opacity: 1;
transform: translateY(0);
}


.navbar-collapse.show .nav-link:nth-child(1) { transition-delay: 0.2s; }
.navbar-collapse.show .nav-link:nth-child(2) { transition-delay: 0.4s; }
.navbar-collapse.show .nav-link:nth-child(3) { transition-delay: 0.6s; }
.navbar-collapse.show .nav-link:nth-child(4) { transition-delay: 0.8s; }


.navbar-brand {
font-weight: 700; 
transition: transform 0.3s ease-in-out; 
}

.navbar-brand:hover {
transform: scale(1.05); 
}

.navbar-toggler {
border: none; 
}

.navbar-toggler-icon {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23F3EDE0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@keyframes fadeInUp {
from {
  opacity: 0;
  transform: translateY(20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

.navbar-collapse {
transform: translateY(-100%);
transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
opacity: 0;
}

.navbar-collapse.show {
transform: translateY(0);
opacity: 1;
}

.nav-link {
opacity: 0;
animation: fadeInUp 0.5s ease forwards;
}

.navbar-collapse.show .nav-link {
opacity: 1;
}

.nav-link:nth-child(1) { animation-delay: 0.2s; }
.nav-link:nth-child(2) { animation-delay: 0.4s; }
.nav-link:nth-child(3) { animation-delay: 0.6s; }
.nav-link:nth-child(4) { animation-delay: 0.8s; }

.nav-link {
margin: 5px 0; 
transition: color 0.3s ease-in-out; 
}

.nav-link:hover, .nav-link:focus {
text-decoration: none; 
}

@media (max-width: 768px) {
.navbar-brand {
  font-size: 18px; 
}

.nav-link {
  font-size: 16px; 
} 
}
 */


/* Navbar styles */


.sidebar.open h2 {
  background-color: transparent !important;
}

/* Navbar and Sidebar Styling */
.navbar-container {
  position: relative;
}

.navbar {
  background-color: #3892CE;
  color: #E3FAFD;
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-logo {
  height: 40px;
  width: auto;
}

.navbar-toggler {
  background: none;
  border: none;
  color: #E3FAFD;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Sidebar styling */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 100%;
  background-color: #4F8CB1;
  color: #E3FAFD;
  padding: 1.5rem 1rem;
  transform: translateX(-100%);
  transition: transform 0.4s ease;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  color: #E3FAFD;
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.sidebar-title {
  font-size: 1.5rem;
  color: #99C3D9;
  margin-bottom: 1rem;
  text-align: center;
  border-bottom: 1px solid #E3FAFD;
  padding-bottom: 1rem;
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.sidebar-item {
  padding: 0.8rem 1rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.sidebar-item a {
  text-decoration: none;
  color: #E3FAFD;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Active and Hover Styles */
.sidebar-item.active,
.sidebar-item:hover {
  background-color: #38ceb3;
}

.sidebar-item.active a {
  color: #E3FAFD;
}

/* Backdrop */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  cursor: pointer;
}

.navbar .navbar-toggler {
  color: white;
  border: white;
}

/* NavBar.css */

.navbar-container {
  position: relative;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #3892CE;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  height: 40px;
}

.navbar-toggler {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: #4F8CB1;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.sidebar.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  align-self: flex-end;
  margin: 10px;
}

.sidebar-title {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
}

.sidebar-list {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0 20px;
}

.sidebar-item {
  padding: 10px 0;
  font-size: 1rem;
  color: white;
  transition: background 0.2s ease;
}

.sidebar-item.active,
.sidebar-item:hover {
  background-color: #3892CE !important;
  border-radius: 5px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
