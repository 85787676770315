/* Login.css */

/* Center form on the page */
.auth-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* background-image: url('../images/background.jpg'); */
  background-color: #3892CE;
}

/* Form card styling */
.auth-form-card {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #D7C6AE !important;
  transition: transform 0.3s;
}

.auth-form-card:hover {
  transform: scale(1.02);
}

.auth-form-card h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
}

.auth-form-card .btn-primary {
  width: 100%;
  padding: 0.75rem;
  font-weight: bold;
}

.auth-form-card .form-control {
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auth-form-card .alert {
  margin-top: 1rem;
}
