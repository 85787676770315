/* UserProfile.css */

/* Container styling */
.profile-container {
    padding-top: 3rem;
    min-height: 100vh;
  }
  
  /* Card styling */
  .profile-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    background-color: #ffffff;
  }
  
  .profile-card .card-body {
    padding: 2rem;
  }
  
  .profile-card .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  /* Form control styling */
  .profile-card .form-control {
    padding: 0.75rem;
    border-radius: 4px;
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
  }
  
  .profile-card .form-control:disabled {
    background-color: #e9ecef;
  }
  
  /* Button styling */
  .btn-primary, .btn-success, .btn-warning, .btn-danger {
    width: 100%;
    padding: 0.75rem;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .btn-primary {
    background-color: #304121;
    border: none;
    border: 1px solid #304121;
  }
  
  .btn-primary:hover {
    background-color: #304121 !important;
    border: 1px solid #304121 !important;
  }
  
  .btn-success {
    background-color: #28a745;
    border: none;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  
  .btn-warning {
    background-color: #D7C6AE !important;
    border: none;
    border: 1px solid #D7C6AE !important;

  }
  
  .btn-warning:hover {
    background-color: #D7C6AE !important;
    border: 1px solid #D7C6AE !important;
  }
  
  .btn-danger {
    background-color: #dc3545;
    border: none;
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  
  /* Modal styling */
  .modal-content {
    border-radius: 10px;
  }
  
  /* Back icon styling */
  .back-icon {
    font-size: 2.4rem;
    cursor: pointer;
    color: #007bff;
    position: absolute;
    top: 55px;
    left: 20px;
  }
  
  .back-icon:hover {
    color: #0056b3;
  }
  
  .card-body-userprofile {
    display: block !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
  }
  