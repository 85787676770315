/* Overall Container */
.category-form-container {
    max-width: 100%;
    width: 850px;
    margin: 40px auto;
    padding: 25px;
    background-color: #f9faff;
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease;
  }
  
  /* Header Section */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d1e0ef;
    padding-bottom: 12px;
    margin-bottom: 24px;
    flex-direction: column;
  }
  
  .header h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #2a2a72;
    letter-spacing: 0.5px;
  }
  
  .action-button {
    background-color: #3b82f6;
    color: #ffffff;
    padding: 10px 24px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 10px;
  }
  
  .action-button:hover {
    background-color: #2563eb;
    box-shadow: 0 6px 12px rgba(59, 130, 246, 0.4);
  }
  
  /* Category and Subcategory Lists */
  .category-list,
  .subcategory-list {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  
  .category-item,
  .subcategory-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 18px;
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .category-item:hover,
  .subcategory-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
  
  .category-item h4,
  .subcategory-item h4 {
    font-size: 1.2rem;
    color: #1f2937;
    margin: 0;
    padding: 10px;
  }
  
  .subcategory-item p {
    color: #4b5563;
    font-size: 0.9rem;
    margin: 0;
    padding: 10px;
  }
  
  /* Button Group for Actions */
  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .delete-button {
    padding: 8px 16px;
    font-size: 0.95rem;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .edit-button {
    background-color: #10b981;
    color: #ffffff;
  }
  
  .edit-button:hover {
    background-color: #059669;
  }
  
  .delete-button {
    background-color: #ef4444;
    color: #ffffff;
  }
  
  .delete-button:hover {
    background-color: #dc2626;
  }
  
  /* Input Fields in Modal */
  .form-input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    margin: 12px 0 20px;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .form-input:focus {
    border-color: #3b82f6;
    outline: none;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.3);
  }
  
  /* Modal Buttons */
  .form-button {
    background-color: #3b82f6;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-button:hover {
    background-color: #2563eb;
    box-shadow: 0 6px 12px rgba(59, 130, 246, 0.4);
  }
  
  .cancel-button {
    background-color: #9ca3af;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #6b7280;
  }
  
  /* Modal Styling */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    width: 90%;
    max-width: 520px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    animation: slide-down 0.3s ease-out;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-10%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .header h2 {
      font-size: 1.6rem;
    }
    
    .action-button, .form-button, .cancel-button {
      padding: 8px 18px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .category-form-container {
      padding: 15px;
      width: 100%;
    }
    
    .header h2 {
      font-size: 1.4rem;
    }
  
    .action-button {
      padding: 6px 14px;
      font-size: 0.8rem;
    }
    
    .edit-button, .delete-button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  }
  