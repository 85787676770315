.product-detail-container {
  background-color: #99C3;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    color: white;
    padding: 20px;
    min-height: 100vh;
    overflow-x: hidden;
  }

  .product-detail-container h1 {
    color: #3892CE !important;
  }
  
  .product-detail-container p {
    color: #4F8CB1 !important;
  }

  .product-detail-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-detail-info {
    text-align: center;
  }
  
  .product-detail-title {
    font-size: 2rem; /* Adjust size as needed */
  }
  
  .product-detail-price {
    font-size: 1.5rem; /* Adjust size as needed */
    color: #4CAF50; /* Adjust color as needed */
  }
  
  .product-detail-description {
    margin: 10px 0;
  }
  
  .product-detail-description-ar {
    font-style: italic;
  }
  
  .product-detail-back {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333; /* Dark background for the button */
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }

  .product-detail-info p {
    display: flex;
    padding: 5px;
    margin: 0;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  /* Additional media queries and styles for responsiveness */
  

  .product-detail-container {
    /* background-color: #99C3D9; */
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 20px;
    min-height: 100vh;
    overflow-x: hidden;
  }
  
  .product-detail-image {
    width: 100%;
    max-width: 600px; /* Set a max width to prevent it from stretching too much */
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .product-detail-info {
    text-align: center;
    padding: 20px;
  }
  
  .product-detail-title {
    font-size: 2rem;
    color: #3892CE;
    margin: 10px 0;
  }
  
  .product-detail-price {
    font-size: 1.5rem;
    color: #4CAF50;
    margin: 10px 0;
  }
  
  .product-detail-description,
  .product-detail-description-ar {
    color: #4F8CB1;
    font-size: 1rem;
    margin: 10px 0;
    max-width: 90%;
  }
  
  .product-detail-description-ar {
    font-style: italic;
  }
  
  .product-detail-back {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .product-detail-title {
      font-size: 1.75rem;
    }
    
    .product-detail-price {
      font-size: 1.25rem;
    }
    
    .product-detail-description,
    .product-detail-description-ar {
      font-size: 0.9rem;
    }
  
    .product-detail-info {
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .product-detail-title {
      font-size: 1.5rem;
    }
  
    .product-detail-price {
      font-size: 1.2rem;
    }
  
    .product-detail-description,
    .product-detail-description-ar {
      font-size: 0.85rem;
    }
  
    .product-detail-info {
      padding: 10px;
    }
  }
  