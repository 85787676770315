/*  */



.card {
  background-color: transparent !important;
  padding: 0 7px;
  padding: 10px;
}

/* .product-card {
    background-color: transparent !important;
    background-size: cover;
    border: none;
    border-radius: 0; 
  } */
  
  .card-title {
    font-family: 'Your font-family', sans-serif; /* Use your custom font */
    font-size: 1.45rem; /* Adjust size as needed */
    color: #4F8CB1 !important;
  }

  .card-text {
    font-family: 'Your font-family', sans-serif;
    /* Add custom styles for descriptions */
  }
  
  .price {
    font-family: 'Your font-family', sans-serif;
    font-size: 1.4rem; /* Adjust size as needed */
    color: #4CAF50; /* Adjust color as needed */
  }
  
  .card-body {
    /* flex: 1 1 auto; */
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
    
}

  /* Add media queries and additional styling as needed */
  


.card-img-top {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px;
}

.card-body {
  padding: 1rem;
}



@media (min-width: 768px) {
  .card-title {
    font-size: 1.25rem; /* Larger size for tablets and up */
  }

  .card-text {
    font-size: 1rem; /* Larger size for tablets and up */
  }

  .price {
    font-size: 1.5rem; /* Larger size for tablets and up */
  }
}

/* Additional responsive adjustments as needed */
h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #84764F; /* Dark background for the headline */
    color: #D7C6AE !important; /* White text color */
    padding: 10px;
    margin-top: 20px;
  }
  
  /* Ensure the .row class has no left or right padding if using Bootstrap 4 */
  .row {
    margin-right: 0;
    margin-left: 0;
    padding: 5px;
  }

  h1 {
    color: #99C3D9 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px !important;
    font-family: serif;
    padding-top: 10px;
    font-weight: 530 !important;
    /* line-height: 2.3 !important; */
    letter-spacing: 2px;
  }

  .product-card {
    width: 300px; /* Set a fixed width */
    height: 400px; /* Set a fixed height */
    /* Add other styling as needed */
}

.card-img-top {
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* This will cover the area without stretching the image */
    width: 100%; /* Image takes full width of the card */
}

.card-body {
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Add an ellipsis to text that overflows */
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* Responsive design considerations */
@media screen and (max-width: 768px) {
    .product-card {
        width: 100%; /* Full width on smaller screens */
        /* Adjust other properties as needed for smaller screens */
    }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 22px;
  padding-top: 30px;
  color: #D7C6AE;
  font-family: -webkit-body;
  padding-bottom: 70px;
}


.product-card-link {
  color: inherit;
  text-decoration: none;
}

.product-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}

.product-card-title {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  margin: 5px 0;
}

.price {
  font-size: 1.2rem;
  font-weight: 700;
  color: #3892CE;
}
