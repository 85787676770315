/* Modal overlay to darken the background */
.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
}
  
  /* Modal content box */
  .modal-content {
    background-color: #fff !important;
    border-radius: 12px;
    padding: 30px;
    width: 500px !important;
    max-height: 80% !important;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Professional shadow */
    transform: translateY(-100px);
    animation: slide-down 0.3s ease forwards; /* Smooth slide-down animation */
    position: relative;
  }
  
  /* Close button for the modal */
  .close {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #333;
    transition: color 0.2s;
  }
  
  .close:hover {
    color: #ff4d4f;
  }
  
  /* Body of the modal */
  .modal-body {
    margin-top: 20px;
    font-size: 1rem;
    color: #333;
  }
  
  /* Buttons in the modal */
  .modal-content .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    border: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.2s ease, opacity 0.2s ease;
  }
  
  .btn-primary {
    background-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-danger {
    background-color: #ff4d4f;
  }
  
  .btn-danger:hover {
    background-color: #e60000;
  }
  
  .btn-secondary {
    background-color: #6c757d;
  }
  
  .btn-secondary:hover {
    background-color: #565e64;
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-100px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  