.footer {
    background-color: #3892CE;
    color: #D7C6AE;
    padding: 20px 0;
    font-size: 1rem;
  }
  
  .footer-logo {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .footer-socials a {
    color: #D7C6AE;
    margin: 0 15px;
    font-size: 1.5rem; /* Adjust the size of the icons */
  }
  
  .footer-socials a:hover,
  .footer-socials a:focus {
    color: #D7C6AE; /* Change color on hover/focus */
  }
  
  .footer-copy {
    margin-top: 10px;
    font-size: 0.875rem;
  }
  
  /* Add to your Footer.css */

.footer-socials a {
  margin: 0 10px;
  color: inherit; /* Or specify a color */
  font-size: 20px; /* Adjust size as needed */
}

.footer-socials a:hover {
  color: #D7C6AE; /* Adjust hover color as needed */
}

.growup {
  color: #D7C6AE;
  text-decoration: none;
  font-family: STC;
}

.growup:hover {
  color: #FAE122;
  font-size: 18px;
}