/* AdminDashboard.css */

* {
  box-sizing: border-box;
}

.admin-dashboard {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.menu-toggler {
  font-size: 1.5rem;
  background: #3892CE;
  color: white;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1001;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition: background 0.3s;
}

.menu-toggler:hover {
  background: #4F8CB1;
}

.sidebar {
  width: 260px;
  height: 100vh;
  background: linear-gradient(180deg, #3892CE, #99C3D9);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  background: #4F8CB1;
  color: white;
  border-bottom: 1px solid #E3FAFD;
  margin-top: 10px;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 1.25rem;
}

.profile-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s, padding-left 0.2s;
  display: flex;
  align-items: center;
}

.sidebar ul li:hover,
.sidebar ul li.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #E3FAFD;
  padding-left: 2rem;
}

.sidebar ul li.active {
  background-color: #E3FAFD;
  color: #3892CE;
  font-weight: bold;
}

.main-content {
  flex-grow: 1;
  padding: 2rem;
  margin-left: 260px;
  transition: margin-left 0.3s ease;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.main-content.shifted {
  margin-left: 0;
}

.section {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.section h2 {
  font-size: 1.75rem;
  color: #3892CE;
  margin-bottom: 1.5rem;
}

.section:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }
  
  .sidebar.open {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
  }
}
